import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid } from "@material-ui/core";
import Cognisphere from "../../assets/images/Cognisphere.png";
import Loader from "../../Pages/Components/Loading/Loading";
import { MasterRouteLanding } from "../../Utilities/utility";
import Header from "../../Pages/Components/Header/Header";
import "./Home.scss";
import {
    backButtonFieldsAction,
    priceInputValuesAction,
    specsInputValuesAction,
    saveDesiredKwAction,
    unitPriceListValuesAction,
} from '../../Redux/Actions/actions';


export default function Home(props) {
    const [routes, setRoutes] = useState([]);
    const dispatch = useDispatch();

    function getMenus() {
        let filtered = MasterRouteLanding.filter((c) => c.roleId === 1);
        setRoutes(filtered);
    }

    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );

    useEffect(() => {
        getMenus();
        dispatch(specsInputValuesAction(""));
        dispatch(priceInputValuesAction(""));
        dispatch(backButtonFieldsAction(false));
        dispatch(saveDesiredKwAction("0"));
        dispatch(unitPriceListValuesAction(""));
    }, []);

  return (
    <>
      <div className="potts-master-block">
        <Header />
        <main className="main-component">
          <img
            src={Cognisphere}
            alt="Cognisphere"
            className="homepage-cognisphere"
          />
          {routes.length ? (
            <div className="bg-landing-page">
              <Container maxWidth="lg">
                <Grid container className="homepage-container">
                  <Grid item xs={12} sm={12} md={12} lg={5} className="z-9">
                    <div className="logged-in-user">
                      <h1 className="logged-in-user-heading">
                        <span className="logged-in-greetings">Hello,</span>
                        <span className="logged-in-user-name">{userDetails.userFirstName}</span>
                      </h1>
                      <p className="logged-in-project-desc">Welcome to MCPC</p>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={7}
                    className="landing-page-menu"
                  >
                    <Grid container spacing={2}>
                      {routes.map((item, index) => (
                        <Grid item xs={12} sm={6} md={6} key={index}>
                          <Link to={item.menuLink} key={index}>
                            <div className="menu-item-container">
                              <div className="menu-item-text-block">
                                <span>{item.menuName}</span>
                              </div>
                            </div>
                          </Link>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </div>
          ) : (
            <Loader />
          )}
        </main>
      </div>
    </>
  );
}
