import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "react-oidc-context";
import { makeStyles } from "@material-ui/core/styles";
import { Container,
  Grid,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  AppBar,
  Tabs,
  Tab,
  Box,
  Typography,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import "./Login.scss";
import SiemensLogo from "../../assets/images/siemens-logo-petrol.svg";
import { AppIDAction, errorMessageAction } from "../../Redux/Actions/actions";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "rgba(0, 204, 204, .05)",
    backdropFilter: "blur(3px)",
    minHeight: "100vh",
    overflow: "hidden",
  },
  paper: {
    padding: theme.spacing(2),
  },
  errorMessage: {
    color: "var(--red)",
    fontSize: "16px",
  },
  loginTabs: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "15px",
    //backgroundColor: "rgba(0, 204, 204, .05)",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Login() {
  const auth = useAuth();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { instance } = useMsal();

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogContent, setDialogContent] = React.useState("");
  const errMessage = useSelector((state) => state.errorMessageReducer);

  const { control, handleSubmit } = useForm();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDialogMessage = (text) => {
    setDialogContent(text);
    setDialogOpen(true);
  };

  const handleClose = () => {
    localStorage.clear();
    sessionStorage.clear();
    setDialogOpen(false);
    dispatch(errorMessageAction(""));
    window.location.href = "/";
  };

  function azureADLogin_ACB() {
    localStorage.setItem("APPLICATIONID", "1");
    //dispatch(auth.signinRedirect);
    dispatch(AppIDAction("1"));
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  }

  function azureADLogin_MCPC() {
    localStorage.setItem("APPLICATIONID", "2");
    //dispatch(auth.signinRedirect);
    dispatch(AppIDAction("2"));
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  }

  function azureADLogin_StdMLFB() {
    localStorage.setItem("APPLICATIONID", "3");
    //dispatch(auth.signinRedirect);
    dispatch(AppIDAction("3"));
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  }

  useEffect(() => {
    if (errMessage !== "") {
      handleDialogMessage(errMessage);
      console.log("Err", errMessage);
    }
  }, [errMessage]);

  return (
    <div className="bg-login-register">
      <div className={classes.root}>
        <Container>
          <Grid container className="pt-lr-mobileContainer">
            <Grid item xs={12}>
              <div className="pt-login-logo-block">
                <img
                  src={SiemensLogo}
                  className="official-logo"
                  alt="Siemens"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={10} md={6} className="pt-lr--project-block">
              <div className="pt-lr--project">
                <p className="pt-lr-project-name">ACB LP Calculator</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={10} md={6} className="pt-lr-master-block">
              <div className={classes.loginTabs}>
                <AppBar position="static">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    variant="scrollable"
                    // scrollButtons="auto"
                  >
                    <Tab
                      className="pl-tab-2i"
                      label="ACB LP Calculator"
                      {...a11yProps(0)}
                    />
                    <Tab label="MCPC" {...a11yProps(1)} />
                    <Tab
                      className="pr-tab-2i"
                      label="Product Mgmt Tool"
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                  <div className="pt-login-siemens_user">
                    <h3 className="heading-margin-5tb-0lr">
                      ACP LP Calculator login{" "}
                    </h3>
                    <span>
                      If you are a Siemens Employee, login via AzureAd
                    </span>
                    <div className="d-flex jc-center myid-login-btn-block">
                      <Link to="/">
                        <Button
                          className="pt-button--hero myid-login-btn mt-1"
                          id="myIDLoginBtn"
                          onClick={azureADLogin_ACB}>
                          <span>Ad Login</span>
                        </Button>
                      </Link>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div className="pt-login-siemens_user">
                    <h3 className="heading-margin-5tb-0lr">MCPC User Login </h3>
                    <span>
                      If you are a Siemens Employee, login via AzureAd
                    </span>
                    <div className="d-flex jc-center myid-login-btn-block">
                      <Link to="/">
                        <Button
                          className="pt-button--hero myid-login-btn mt-1"
                          id="myIDLoginBtn"
                          onClick={azureADLogin_MCPC}>
                          <span>AD Login</span>
                        </Button>
                      </Link>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <div className="pt-login-siemens_user">
                    <h3 className="heading-margin-5tb-0lr">
                      Siemens Employee Login{" "}
                    </h3>
                    <span>
                      If you are a Siemens Employee, login via AzureAd
                    </span>
                    <div className="d-flex jc-center myid-login-btn-block">
                      <Link to="/">
                        <Button
                          className="pt-button--hero myid-login-btn mt-1"
                          id="myIDLoginBtn"
                          onClick={azureADLogin_StdMLFB}>
                          <span>AD Login</span>
                        </Button>
                      </Link>
                    </div>
                  </div>
                </TabPanel>
                {/* <h3>Siemens Employee Login </h3> */}
              </div>
            </Grid>
          </Grid>

          <div>
            <Dialog
              open={dialogOpen}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">
                <h4>Error !!!</h4>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {dialogContent}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  type="input"
                  className="pt-button--secondary supplier-login-btn"
                  id="SupplierOTPResend">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Container>
      </div>
    </div>
  );
}
