import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { LoginRoute } from "./Routing/LoginRoute";
import MasterRoute from "./Routing/MasterRoute";
import Header from "./Pages/Components/Header/Header";
import Loader from "./Pages/Components/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import "./assets/breakpoint.scss";
import "./App.css";
import Home from "./Pages/Home/Home";
import MLFBInput from "./Pages/MLFB/MLFBInput";
import MLFBList from "./Pages/MLFB/MLFBList";
import MasterData from "./Pages/Master/MasterData";
import UserManagement from "./Pages/UserManagement/UserManagement";
import {
  authenticateUserBasedOnValidToken,
  validateTheUserBasedOnApi,
} from "./Redux/API/api_Login";
import {
  authUserAction,
  saveAccessTokenAction,
  saveAzureAccessTokenAction,
  storeUserDetailsAction,
} from "./Redux/Actions/actions";
import Contact from "./Pages/Contact/Contact";
import MCPCHome from "./MCPC/Home/Home";
import Master from "./MCPC/Masters/Master";
import CreateOffer from "./MCPC/CreateOffer/CreateOffer";
import StandardMLFBList from "./Pages/StandardMLFB/StandardMLFBList";
import RTC from "./Pages/RTC/RTC";
import { appId } from "./Utilities/utility";
import IdleTimer from "react-idle-timer";
import { useMsal } from "@azure/msal-react";

export default function App() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const employeeFirstName = useState();
  const employeeLastName = useState();
  const [isLoader, setIsLoader] = useState(false);
  const [mlfbList, setMlfbList] = useState([]);
  const [applicationId, setapplicationId] = useState(
    localStorage.getItem("APPLICATIONID")
  );
  const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const AppID = useSelector((state) => state.AppIDReducer.AppID);

  useEffect(() => {
    if (activeAccount) {
      setIsLoader(true);
      let gid = accounts[0].idTokenClaims.gid;
      //dispatch(authUserAction(window.btoa(gid)));
      dispatch(saveAzureAccessTokenAction(activeAccount.idToken));
      validateTheUserBasedOnApi(dispatch, gid, activeAccount.idToken, AppID);
      setIsLoader(false);
    } else {
      setIsLoader(false);
    }
  }, [accounts]);

  function sessionExpire(auth, dispatch) {
    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;
    window.history.pushState({ path: newurl }, "", `/`);
    localStorage.clear();
    sessionStorage.clear();
    if (auth !== undefined) {
      dispatch(storeUserDetailsAction(""));
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    window.location.href = "/";
  }

  function getMLFBListFromMLFBInput(data) {
    setMlfbList(data);
  }

  var routes = [];
  if (applicationId == "1") {
    routes = (
      <Switch>
        <Redirect from="/signin-oidc*" to="/" />
        <Route
          exact
          path={`/`}
          render={(props) => (
            <Home firstName={employeeFirstName} lastName={employeeLastName} />
          )}
        />
        <Route
          exact
          path={`/MLFBInput`}
          render={(props) => (
            <MLFBInput
              Token={token}
              getMLFBList={getMLFBListFromMLFBInput.bind(this)}
            />
          )}
        />
        <Route
          exact
          path={`/MLFBList`}
          render={(props) => <MLFBList Token={token} />}
        />
        <Route
          exact
          path={`/MasterData`}
          render={(props) => (
            <MasterData
              Token={token}
              userDetails={userDetails}
              auth={auth}
              dispatch={dispatch}
            />
          )}
        />        
        <Route
          exact
          path={`/UserManagement`}
          render={(props) => (
            <UserManagement
              Token={token}
              userDetails={userDetails}
              auth={auth}
              dispatch={dispatch}
            />
          )}
        />
        <Route
          exact
          path={`/RTC`}
          render={(props) => (
            <RTC
              Token={token}
              userDetails={userDetails}
              auth={auth}
              dispatch={dispatch}
            />
          )}
        />
      </Switch>
    );
  }
  if (applicationId == "2") {
    routes = (
      <Switch>
        <Redirect from="/signin-oidc*" to="/" />
        <Route
          exact
          path={`/`}
          render={(props) => (
            <MCPCHome
              firstName={employeeFirstName}
              lastName={employeeLastName}
            />
          )}
        />
        <Route
          exact
          path={`/Contact`}
          render={(props) => <Contact Token={token} />}
        />
        <Route exact path={`/Master`} render={() => <Master />} />
        <Route
          exact
          path={`/CreateOffer`}
          render={(props) => <CreateOffer Token={token} />}
        />
      </Switch>
    );
  }
  if (applicationId == "3") {
    routes = (
      <Switch>
        <Redirect from="/signin-oidc*" to="/" />
        <Route
          exact
          path={`/`}
          render={(props) => (
            <StandardMLFBList Token={token} userDetails={userDetails} />
          )}
        />
      </Switch>
    );
  }
  return (
    <BrowserRouter>
      {isLoader ? <Loader loaderText="Loading...." /> : <></>}
      {userDetails.roleId !== 0 && userDetails.isActive == true ? (
        <div className="potts-master-block">
          <Header>Header Component</Header>
          <main className="main-component">{routes}</main>
        </div>
      ) : (
        LoginRoute
      )}
      <div>
        <IdleTimer
          timeout={1000 * 60 * 15}
          onIdle={sessionExpire}
          debounce={250}
        />
      </div>
    </BrowserRouter>
  );
}
