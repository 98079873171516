import React, { useState, useEffect } from "react";
import { Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Button } from '@material-ui/core';
import { baseApiURL, appId } from "../../Utilities/utility";
import { useSelector } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import ConfirmDialog from './ConfirmDialog';


const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => (
    <Clear {...props} ref={ref} className="bg-transparent" />
  )),
  Search: forwardRef((props, ref) => (
    <Search {...props} ref={ref} className="bg-transparent" />
  )),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownwardIcon {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};


export default function OrderTable(props) {
    const userDetails = useSelector((state) => state.storeUserDetailsReducer.userData);
    const [MlfbData, setMlfbData] = useState([]);
    const [open, setopen] = useState('');
    const [openAlert, setopenAlert] = useState('');
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openClassName, setopenClassName] = useState('');
    const [openType, setopenType] = useState('');
    const [alertType, setalertType] = useState("Alert");
    const [alerterrorclass, setalerterrorclass] = useState("bg-red clr-white");
    const [openLoader, setopenLoader] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    
    useEffect(() => {
        getAllMCPCMLFB();
    }, [props]);
    function handleAlertClose() {
        setopen(false);
    };
    const handleClose = () => {
        setOpenSuccess(false);
    };
    function showMsg(popen, popenAlert, popenClassName, popenType) {
        setopen(popen);
        setopenAlert(popenAlert);
        setopenClassName(popenClassName);
        setopenType(popenType);
    }

  var columns = [
    { title: "Sr No", field: "id", hidden: true },
      { title: "Motor", field: "motor" },
    { title: "Quantity", field: "iQuantity" },
    { title: "Motor Types", field: "sFinalMotorName" },
    { title: "KWOutput", field: "sOutputinKwvalue" },
    {
      title: "Name Plate O/P",
        field: "role", hidden: true
    },
    { title: "Frame Size", field: "sFrameSizeValue" },
    { title: "Voltage", field: "iVltgValue" },
    { title: "Frequency", field: "sFreqValue" },
    { title: "Z-Codes", field: "sZcodeOptions" },
    { title: "Requested Discounts", field: "dDiscountValue" },
    { title: "Non Std Feature in %", field: "dZcodeTotalPer" },
    { title: "Non Std Feature in Absolute Value", field: "dZcodeTotalAbs" },
    { title: "Unit List Price", field: "dListPrice" },
    { title: "Total List Price", field: "dTotalLp" },
    { title: "Unit KP", field: "dUnitNetPrice" },
    { title: "Total KP", field: "dTotalNetPrice" },
    { title: "Regional Price", field: "dRp" },
    { title: "%SM at RP", field: "dPerSmrp" },
    ];
    
    const getAllMCPCMLFB = async () => {
        const postData = {
            appId: appId,
            userId: parseInt(userDetails.userId)
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + props.Token,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(postData),
        };

        const response = await fetch(
            baseApiURL + "CreateOffer/getAllMCPCMLFB", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
        }
        else {
            const responseJson = await response.json();
            console.log(responseJson);
            let result = responseJson;
            setMlfbData(responseJson);
            console.log("All MCPC MLFB Data",result);
        }
    }

  const handleRowUpdate = (newData, oldData, resolve) => {
    alert("updated");
  };
    const handleRowDelete = (oldData, resolve, reject) => {
        //alert("Are you sur want to delete the row");
        DeleteMLFBDetails(oldData, resolve, reject);
    };
    async function DeleteAllData() {
        setopenLoader(true);
        const postData = {
            appId: appId,
            userId: parseInt(userDetails.userId)
        };
        const options = {
            method: "put",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + props.Token,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(postData),
        };
        const response = await fetch(
            baseApiURL + "CreateOffer/DeleteAllData", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
        }
        else {
            const responseJson = await response.json();
            console.log(responseJson.result);
            let result = responseJson.result;
            showMsg(true, result.responseMsg, "bg-light-green clr-white", "Message");
            getAllMCPCMLFB();
            //return result;

        }
        setopenLoader(false);
    }

    async function DeleteMLFBDetails(oldData, resolve, reject) {
        console.log("oldData", oldData)
        const postData = {
            appId: appId,
            IMotorPriceCalculatorDataMlfbid: parseInt(oldData.iMotorPriceCalculatorDataMlfbid)
        };
        const options = {
            method: "put",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + props.Token,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(postData),
        };
        const response = await fetch(
            baseApiURL + "CreateOffer/DeleteMLFBDetails", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
        }
        else {
            const responseJson = await response.json();
            console.log(responseJson.result);
            let result = responseJson.result;
            showMsg(true, result.responseMsg, "bg-light-green clr-white", "Message");
            getAllMCPCMLFB();
            resolve();
            //return result;

        }
    }

  return (
    <div className="mt-1 mb-1">
      <Grid container spacing={1}>
              <Grid item xs={12}>
                  <Grid container item xs={12}>
                      <Grid item xs={3}><h3></h3></Grid>
                      <Grid item xs={9} className="d-flex jc-flex-end">
                          <a href={baseApiURL + "CreateOffer/ExportUserCourseReport?UserId=" + parseInt(userDetails.userId)}>
                              <Button
                                  className="pt-button--secondary mt-1"
                                  id="DownloadDataBtn"
                              >
                                  Download Report
                              </Button></a>
                              <Button
                              className="pt-button--secondary mt-1"
                              id="DeleteAllDataBtn" onClick={() => setConfirmOpen(true)}
                              //onClick={DeleteAllData}
                              >
                                  Delete All MLFB
                          </Button>
                          <ConfirmDialog
                              title="Delete?"
                              open={confirmOpen}
                              setOpen={setConfirmOpen}
                              onConfirm={DeleteAllData}
                          >
                              Are you sure want to delete all data
                          </ConfirmDialog>
                          
                      </Grid>
                  </Grid>
          <MaterialTable
            title=""
            columns={columns}
                      data={MlfbData}
                     // data={data}
            icons={tableIcons}
            options={{
              //headerStyle: tableHeaderStyle,
              draggable: false,
                emptyRowsWhenPaging: false,
                exportButton: { csv: true },
            }}
            editable={{
               onRowDelete: (oldData) =>
               new Promise((resolve) => {
               handleRowDelete(oldData, resolve)
               }),
                          //  onRowUpdate: (newData, oldData) =>
                          //    new Promise((resolve) => {
                          //      handleRowUpdate(newData, oldData, resolve);
                          //   }),
                      }}
                  />

        </Grid>
      </Grid>
          <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadSuccess">
              <DialogTitle className={openClassName} style={{ cursor: 'move' }} id="draggable-dialog-title">
                  {openType}
              </DialogTitle>
              <DialogContent>
                  <DialogContentText className="clr-black">
                      {openAlert}
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button variant="contained" autoFocus onClick={handleAlertClose} className="ns-btn-secondary-light">
                      <span className="f-16 fw-bold">OK</span>
                  </Button>
              </DialogActions>
          </Dialog>
          <Dialog
              open={openLoader}
              //onClose={handleClose}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadSuccess"
              PaperProps={{
                  style: {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      borderColor: "transparent"
                  },
              }}>
              <DialogContent>
                  <CircularProgress />
              </DialogContent>
          </Dialog>

    </div>
  );
}
