export const authUserAction = (gid) => {
  return {
    type: "myIDLogin",
    payLoad: gid,
  };
};
export const storeUserDetailsAction = (userData) => {
  return {
    type: "storeUserData",
    payLoad: userData,
  };
};
export const saveAccessTokenAction = (token) => {
  return {
    type: "saveAccessToken",
    payLoad: token,
  };
};
export const saveAzureAccessTokenAction = (token) => {
  return {
    type: "saveAzureAccessToken",
    payLoad: token,
  };
};
export const errorMessageAction = (errorMessage) => {
  return {
    type: "errorMessage",
    payLoad: errorMessage,
  };
};

export const AppIDAction = (AppID) => {
    return {
        type: "AppID",
        payLoad: AppID,
    };
};

//Bulk Upload Success
export const bulkUploadSuccessAction = (uploadResponse) => {
  return {
    type: "bulkUploadSuccess",
    payLoad: uploadResponse,
  };
};
//MCPC Create offer
export const specsInputValuesAction = (specsInputs) => {
    return {
        type: "StorespecsInputs",
        payLoad: specsInputs,
    };
};
export const priceInputValuesAction = (priceInputs) => {
    return {
        type: "StorepriceInputs",
        payLoad: priceInputs,
    };
};
export const backButtonFieldsAction = (backButtonFields) => {
    return {
        type: 'backButtonFields',
        payLoad: backButtonFields
    }
}

export const storeNonstdCodeAction = (nonstdCode) => {
    return {
        type: 'storeNonstdCode',
        payLoad: nonstdCode
    };
};
export const saveDesiredKwAction = (DesiredKw) => {
    return {
        type: "saveDesiredKw",
        payLoad: DesiredKw,
    };
};
export const unitPriceListValuesAction = (unitPriceList) => {
    return {
        type: "StoreunitPrice",
        payLoad: unitPriceList,
    };
};



