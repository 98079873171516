//export const baseApiURL = "https://acblpcalculatoruat.si-in.siemens.cloud/api/"; 
export const baseApiURL = "https://sentronacboffertoolapi.si-in.siemens.cloud/api/"
//export const baseApiURL = "http://localhost:58755/api/"
export const appId = "3";
//export const baseApiURL1 = "http://localhost:58755";

export const LandingAdmin = [
  {
    roleId: 1,
    role: "Administrator",
    menuLink: "/Master",
    menuName: "Master Data",
  },
  {
    roleId: 1,
    role: "Administrator",
    menuLink: "/myAssignment",
    menuName: "My Assignment",
  },
  {
    roleId: 1,
    role: "Administrator",
    menuLink: "/myWorkflow",
    menuName: "My Workflow",
  },
  {
    roleId: 1,
    role: "Administrator",
    menuLink: "/createOffer",
    menuName: "Create Offer",
  },
  {
    roleId: 1,
    role: "Administrator",
    menuLink: "/MCPCAdmin",
    menuName: "Admin",
  },
  {
    roleId: 1,
    role: "Administrator",
    menuLink: "/Reports",
    menuName: "Reports",
  },
];

export const MasterRouteLanding = [...LandingAdmin];
