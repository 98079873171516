import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useSelector } from "react-redux";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import ListItemText from '@material-ui/core/ListItemText';
import DrawerMenuIcon from "../../../assets/icons/Menu.svg";
import SiemensLogoWhite from "../../../assets/images/siemens-logo-white.svg";
import HomeIcon from '@material-ui/icons/Home';
import ViewListIcon from '@material-ui/icons/ViewList';
import InputIcon from '@material-ui/icons/Input';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import FlightTakeoffOutlinedIcon from '@material-ui/icons/FlightTakeoffOutlined';
import DevicesOutlinedIcon from '@material-ui/icons/DevicesOutlined';
import InsertLink from '@material-ui/icons/InsertLink';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import ServicesGroup from "../../../assets/icons/groups.svg";
import "./SideDrawer.scss";

const useStyles = makeStyles({
    list: {
        width: 300,
    },
    fullList: {
        width: 'auto',
    },
});

export default function SideDrawer() {
    const classes = useStyles();
    const [drawerState, setDrawerState] = React.useState({
        left: false,
    });
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setDrawerState({ ...drawerState, [anchor]: open });
    };

    useEffect(() => {
       getMenus();
    }, [userDetails]);
    const [routes, setRoutes] = useState([]);

    function getMenus() {
    
        if (userDetails.roleId == 1) {
            setRoutes(sideDrawerItems);
        }
        else {
            setRoutes(sideDrawerItemsForSalesUser);
        }
    }

    const sideDrawerItems = [
        { icon: <HomeIcon />, title: "Home", link: "/" },
        { icon: <InputIcon />, title: "ACB LP calculator", link: "MLFBInput" },
      /*{ icon: <ViewListIcon />, title: "MLFB List", link: "MLFBList" },*/
        { icon: <InsertLink />, title: "3WA Graphical Configurator", link:"https://mall.industry.siemens.com/mall/en/WW/Catalog/StartConfigurator?configId=39&nodeId=10313567&kmat=3WA", target: "_blank" },
        { icon: < ViewListIcon />, title: "ACB – EP Arena", link: "https://siemens.sharepoint.com/teams/SIEPArena_P0014162/SitePages/SENTRON%20Air%20Circuit%20Breakers.aspx", target: "_blank" },
        //{ icon: <DevicesOutlinedIcon />, title: "3WL / 3WT LP calculator", link: "https://besys.siemens.co.in/ABD12_new/ZLP12xd/MRP12xx01Z9X.aspx", target: "_blank" },
        { icon: <PersonAddIcon />, title: "User management", link: "UserManagement" },
        { icon: <PersonIcon />, title: "Master data update", link: "MasterData" },
        { icon: <CardMembershipIcon  />, title: "RTC", link: "RTC" },

   
    ];
    const sideDrawerItemsForSalesUser = [
        { icon: <HomeIcon />, title: "Home", link: "/" },
        { icon: <InputIcon />, title: "ACB LP calculator", link: "MLFBInput" },
        { icon: <ViewListIcon />, title: "RTC", link: "RTC" },
        { icon: <InsertLink />, title: "3WA Graphical Configurator", link: "https://mall.industry.siemens.com/mall/en/WW/Catalog/StartConfigurator?configId=39&nodeId=10313567&kmat=3WA", target: "_blank" },
        { icon: < ViewListIcon />, title: "ACB – EP Arena", link: "https://siemens.sharepoint.com/teams/SIEPArena_P0014162/SitePages/SENTRON%20Air%20Circuit%20Breakers.aspx", target: "_blank" },
        //{ icon: <DevicesOutlinedIcon />, title: "3WL / 3WT LP calculator", link: "https://besys.siemens.co.in/ABD12_new/ZLP12xd/MRP12xx01Z9X.aspx", target: "_blank" },
        //{ icon: <PersonAddIcon />, title: "User Management", link: "UserManagement" },
        //{ icon: <PersonIcon />, title: "Master", link: "MasterData" },

    ];

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Link to="/" className="d-flex jc-center side-drawer-logo">
                <img src={SiemensLogoWhite} alt="Siemens" className="official-logo" />
            </Link>
            <List className="side-drawer-menu-list">
                {routes.map((item, index) => (
                    /*<Link to={item.link} key={item.title}>*/
                    <a href={item.link} key={item.title} target={item.target}>
                        <ListItem button>
                            <ListItemIcon className="side-drawer-icons">{item.icon}</ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItem>
                    </a>
                ))}
            </List>
            <div className="sideDrawer-footer-block">
                <p>© 2021 Siemens Intranet</p>
            </div>
        </div>
    );

    return (
        <React.Fragment>
            {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <img src={DrawerMenuIcon} alt="Besys Menu" className="besys-menu-icon" onClick={toggleDrawer(anchor, true)} />
                    <SwipeableDrawer
                        anchor={anchor}
                        open={drawerState[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </React.Fragment>
    );
}
